// SHADES PALETTE
$glxy-blue-50: #e3f2fd;
$glxy-blue-100: #bbdefb;
$glxy-blue-200: #90caf9;
$glxy-blue-300: #64b5f6;
$glxy-blue-400: #42a5f5;
$glxy-blue-500: #2196f3;
$glxy-blue-600: #1e88e5;
$glxy-blue-700: #1976d2;
$glxy-blue-800: #1565c0;
$glxy-blue-900: #0d47a1;

$glxy-green-50: #e8f5e9;
$glxy-green-100: #c8e6c9;
$glxy-green-200: #a5d6a7;
$glxy-green-300: #81c784;
$glxy-green-400: #66bb6a;
$glxy-green-500: #4caf50;
$glxy-green-600: #43a047;
$glxy-green-700: #388e3c;
$glxy-green-800: #2e7d32;
$glxy-green-900: #1b5e20;

$glxy-red-50: #ffebee;
$glxy-red-100: #ffcdd2;
$glxy-red-200: #ef9a9a;
$glxy-red-300: #e57373;
$glxy-red-400: #ef5350;
$glxy-red-500: #f44336;
$glxy-red-600: #e53935;
$glxy-red-700: #d32f2f;
$glxy-red-800: #c62828;
$glxy-red-900: #b71c1c;

$glxy-grey-50: #fafafa;
$glxy-grey-100: #f5f5f5;
$glxy-grey-200: #eeeeee;
$glxy-grey-300: #e0e0e0;
$glxy-grey-400: #bdbdbd;
$glxy-grey-500: #9e9e9e;
$glxy-grey-600: #757575;
$glxy-grey-700: #616161;
$glxy-grey-800: #424242;
$glxy-grey-900: #212121;
$glxy-yellow-50: #fff8e1;
$glxy-yellow-100: #ffecb3;
$glxy-yellow-200: #ffe082;
$glxy-yellow-300: #ffd54f;
$glxy-yellow-400: #ffca28;
$glxy-yellow-500: #ffc107;
$glxy-yellow-600: #ffb300;
$glxy-yellow-700: #ffa000;
$glxy-yellow-800: #ff8f00;
$glxy-yellow-900: #ff6f00;

// NAMED PALETTE
$white: #ffffff;
$lightest-grey: $glxy-grey-50;
$lighter-grey: $glxy-grey-100;
$light-grey: $glxy-grey-300;
$grey: $glxy-grey-500;
$dark-grey: $glxy-grey-700;
$darker-grey: $glxy-grey-900;
$black: #000;

$light-red: $glxy-red-50;
$red: $glxy-red-700;
$dark-red: $glxy-red-800;

$light-yellow: $glxy-yellow-50;
$yellow: $glxy-yellow-800;
$dark-yellow: $glxy-yellow-800;

$light-green: $glxy-green-50;
$green: $glxy-green-700;
$dark-green: $glxy-green-800;
$vendasta-green: #3f9b63;

$light-blue: $glxy-blue-50;
$blue: $glxy-blue-700;
$dark-blue: $glxy-blue-800;

// deprecated:
$lightest-gray: $glxy-grey-50;
$lighter-gray: $glxy-grey-100;
$light-gray: $glxy-grey-300;
$gray: $glxy-grey-500;
$dark-gray: $glxy-grey-700;
$darker-gray: $glxy-grey-900;
$primary-accent-color: $blue !default;
$primary-border-color: $light-gray !default;
$primary-font-color: var(--primary-text-color, $glxy-grey-900);
$secondary-font-color: var(--secondary-text-color, $glxy-grey-700);
$tertiary-font-color: var(--tertiary-text-color, $glxy-grey-500);

// UI colors
// semantic naming guide: [prominence-][sentiment-][usage][-interaction]-color
// when you add a color here, define the light and dark variants in each theme file under /base_themes
// the fallbacks here match light theme.
$primary-text-color: var(--primary-text-color, $glxy-grey-900);
$secondary-text-color: var(--secondary-text-color, $glxy-grey-700);
$tertiary-text-color: var(--tertiary-text-color, $glxy-grey-500);
$border-color: var(--border-color, $glxy-grey-300);
$weak-border-color: var(--weak-border-color, $glxy-grey-100);
$primary-color: var(--primary-color, $glxy-blue-700);
$icon-color: var(--icon-color, rgba(0, 0, 0, 0.54));
$primary-background-color: var(--primary-background-color, $glxy-grey-50);
$primary-background-selected-color: var(--primary-background-selected-color, #{rgba($glxy-blue-700, 0.16)});
$secondary-background-color: var(--secondary-background-color, $glxy-grey-100);
$card-background-color: var(--card-background-color, $white);
$card-overlay-color: var(--card-overlay-color, rgba(255, 255, 255, 0.5));
$row-hover-bg-color: var(--row-hover-bg-color, #f5f9fc);

$link-color: var(--link-color, $glxy-blue-700);
$link-hover-color: var(--link-hover-color, $glxy-blue-800);

$primary-button-bg-hover-color: var(--primary-button-bg-hover-color, $glxy-blue-800);
$warn-button-bg-hover-color: var(--warn-button-bg-hover-color, $glxy-red-800);
$secondary-button-border-strong-color: var(
  --secondary-button-border-strong-color,
  rgba(0, 0, 0, 0.2)
); //TODO: fix this naming

$info-background-color: var(--info-background-color, $glxy-blue-50);
$info-border-color: var(--info-border-color, $glxy-blue-200);
$info-icon-color: var(--info-icon-color, $glxy-blue-800);

$warn-background-color: var(--warn-background-color, $glxy-yellow-50);
$warn-border-color: var(--warn-border-color, $glxy-yellow-500);
$warn-icon-color: var(--warn-icon-color, $glxy-yellow-800);

$success-background-color: var(--success-background-color, $glxy-green-50);
$success-border-color: var(--success-border-color, $glxy-green-300);
$success-icon-color: var(--success-icon-color, $glxy-green-800);
$success-text-color: var(--success-text-color, $glxy-green-800);

$error-background-color: var(--error-background-color, $glxy-red-50);
$error-border-color: var(--error-border-color, $glxy-red-600);
$error-icon-color: var(--error-icon-color, $glxy-red-800);
$error-text-color: var(--error-text-color, $glxy-red-800);

$contrast-background-color: var(--contrast-background-color, $glxy-grey-900);
$contrast-border-color: var(--contrast-border-color, $glxy-grey-700);
$contrast-icon-color: var(--contrast-icon-color, $glxy-grey-200);
$contrast-text-color: var(--contrast-text-color, $white);

$field-border-color: var(--field-border-color, #{rgba($glxy-grey-400, 0.6)});
$field-border-disabled-color: var(--field-border-disabled-color, $glxy-grey-300);
$field-background-color: var(--field-background-color, $white);
$field-background-disabled-color: var(--field-background-disabled-color, $glxy-grey-50);
$select-arrow-color: var(--select-arrow-color, rgba(0, 0, 0, 0.54));

$nav-list-item-active-color: var(--nav-list-item-active-color, $glxy-grey-200);
$nav-list-item-hover-color: var(--nav-list-item-hover-color, $glxy-grey-100);
$sublist-background-color: var(--sublist-background-color, $glxy-grey-100);
$nav-item-text-color: var(--nav-item-text-color, $glxy-grey-900);
