// Base spacing values
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 16px;
$spacing-4: 24px;
$spacing-5: 40px;

// Negative spacing
$negative-1: -1 * $spacing-1;
$negative-2: -1 * $spacing-2;
$negative-3: -1 * $spacing-3;
$negative-4: -1 * $spacing-4;
$negative-5: -1 * $spacing-5;

// galaxy consts
$atlas-bar-height: var(--atlas-bar-height, 40px) !default;

$glxy-page-toolbar-min-height: 60px;
$glxy-page-extended-toolbar-min-height: 52px;

// Gutter widths for backwards compatibility
$gutter-width-micro: $spacing-1;
$gutter-width-tiny: $spacing-2;
$gutter-width-dense: $spacing-3;
$gutter-width: $spacing-4;
$gutter-width-wide: $spacing-5;

// Navigation toolbar heights
$glxy-navigation-toolbar-height-mobile: 48px !default;
$glxy-navigation-toolbar-height-desktop: 64px !default;
