@use 'design-tokens' as *;

.glxy-form-field .mat-mdc-select {
  height: 36px;
  font-size: $font-preset-4-size;
  width: 100%;
  color: $primary-font-color;
  flex-grow: 3;
  flex-shrink: 3;

  .mat-mdc-select-trigger {
    height: 100%;
    padding: 0 12px;
  }
  .mat-mdc-select-value {
    vertical-align: middle;
  }

  .mat-mdc-select-placeholder {
    color: $tertiary-font-color;
    opacity: 0.75;
  }

  @media (max-width: $mobile-breakpoint-max) {
    font-size: $font-preset-3-size;
    height: 40px;
  }
}

glxy-form-field.glxy-form-field-disbled .mat-mdc-select {
  background-image: unset;

  .mat-mdc-select-arrow {
    opacity: 0.5;
  }
}

.glxy-form-field.size-small .mat-mdc-select {
  height: 30px;
}

.glxy-form-field.size-large .mat-mdc-select {
  height: 46px;
  font-size: 15px;

  .mat-mdc-select-trigger {
    padding: 0 14px;
  }
}
