.glxy-form-field .mat-date-range-input {
  padding: 0 12px;

  input.mat-date-range-input-inner {
    // MatDateRangeInput does some funky stuff on the
    // mat-start-date input. We unset the input height
    // to fix the weirdness
    height: unset;
    padding: unset;
  }
  .mat-date-range-input-container {
    // making sure the input container is the same height as the
    // other glxy form field inputs
    height: 36px;
  }

  .mat-date-range-input-separator-hidden {
    // don't completely hide the separator, just make it transparent when no date selected
    opacity: 0.2;
  }
}

.glxy-form-field .input-wrapper .mat-mdc-icon-button {
  // So the button hover doesnt look like a round peg in a square hole
  border-radius: 0;

  .mdc-icon-button__ripple {
    border-radius: 0;
  }
}
