@use 'design-tokens' as *;

// we need to target `select.mat-input-element` too because a material update
// has overridden our styles when a mat-form-field's styles have been loaded

.glxy-form-field select,
.glxy-form-field select.mat-mdc-input-element,
.glxy-form-field select.mat-input-element {
  // TODO: remove legacy style ruleset after MDC migration
  position: relative;
  border: none;
  padding: 0 12px;
  padding-right: 40px;
  outline: none;
  height: 36px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;

  // remove mat-input-element overrides
  top: unset;
  margin-bottom: unset;

  // Hide the default arrow
  appearance: none;
  // Hide the default arrow in Internet Explorer 11
  &::-ms-expand {
    display: none;
  }

  color: $primary-text-color;
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="rgb(128, 128, 128)"><path d="M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z" fill-rule="evenodd"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 16px auto;
}

.glxy-form-field.glxy-form-field-disbled select {
  cursor: unset;
}

// Sizes

.glxy-form-field.size-small select {
  height: 30px;
}

.glxy-form-field.size-large select {
  height: 46px;
  font-size: 15px;
  padding-left: 14px;
  padding-right: 14px;
}
