@use '@angular/material' as mat;
@use 'design-tokens' as *;

.glxy-form-field {
  // we need to make the checkbox smaller to fit in the form field
  // https://material.angular.io/guide/theming#customizing-density
  @include mat.checkbox-density(-2);
}

.glxy-form-field .mat-mdc-checkbox {
  display: block;
  margin-left: -5px;
  padding-bottom: 8px;
  padding-top: 2px;

  .mdc-form-field {
    align-items: flex-start;
  }

  label {
    padding-top: 6px;
  }
}

// This is needed to add color to the checkbox when not disabled or checked
.glxy-form-field
  .mat-mdc-checkbox:not(.mat-mdc-checkbox-disabled)
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  background-color: $field-background-color;
}
