@use 'design-tokens' as *;

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Warning: ViewEncapsulation is disabled in this component
// Any styles defined here will affect the entire application
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$inputWrapperBoxShadow:
  0px 1px 2px 0px rgb(0 0 0 / 5%),
  0px 1px 0px 1px rgb(0 0 0 / 1%);
$inputWrapperBoxShadowDisabled: 0px 0px 4px 0px rgb(0 0 0 / 5%);

.glxy-form-field {
  display: block;
  position: relative;

  &.bottom-spacing--default {
    margin-bottom: 24px;
  }

  &.bottom-spacing--small {
    margin-bottom: 16px;
  }
}

//
//
// Label

.glxy-form-field .label-and-hint label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: $primary-text-color;

  &:empty {
    display: none;
  }

  span.label-required {
    font-weight: 400;
    font-size: 12px;
    color: $secondary-text-color;
    margin-left: $spacing-1;
    background-color: $secondary-background-color;
    padding: 1px 6px;
    border-radius: 6px;
    display: inline-block;

    &.required-has-error {
      color: $error-text-color;
      background-color: $error-background-color;
    }
  }
}

//
//
// Hints

.glxy-hint,
.glxy-label-hint {
  display: block;
  color: $secondary-text-color;
  line-height: 1.4;
  font-size: 13px;

  a {
    text-decoration: none;
    color: $link-color;
  }
}
.glxy-hint {
  margin-top: $spacing-2;
  margin-bottom: $spacing-2;
}
.glxy-label-hint {
  margin-top: 2px;
  margin-bottom: $spacing-2;
}

// disabled
.glxy-form-field.glxy-form-field-disbled .glxy-hint,
.glxy-form-field.glxy-form-field-disbled .glxy-label-hint {
  color: $tertiary-font-color;
}

//
//
// Label and hint spacing

.glxy-form-field .label-and-hint label {
  margin-bottom: $spacing-2;
}

.glxy-form-field .label-and-hint label:not(.visually-hidden):has(.glxy-label) + .glxy-label-hint {
  margin-top: 2px - $spacing-2;
  margin-bottom: $spacing-2;
}

//
//
// Error

.glxy-error {
  display: block;
  color: $error-text-color;
  font-size: 13px;
  margin-top: $spacing-2;
  margin-bottom: $spacing-2;
}

//
//
// Input Wrapper

.glxy-form-field .input-wrapper {
  border: 1px solid $field-border-color;
  background-color: $field-background-color;
  border-radius: $default-border-radius;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: $inputWrapperBoxShadow;

  &:focus-within {
    border-color: $glxy-blue-500;
    box-shadow:
      0 0 0px 1px $glxy-blue-500,
      0 0 2px 5px #{$glxy-blue-500}30;
  }

  &.hide-decoration {
    border-color: transparent;
    box-shadow: none;
  }

  &:empty {
    display: none;
  }
}

// disabled
.glxy-form-field.glxy-form-field-disbled .input-wrapper {
  background-color: $field-background-disabled-color;
  color: $tertiary-text-color;
  box-shadow: $inputWrapperBoxShadowDisabled;

  &:focus-within {
    border-color: $field-border-color;
    box-shadow: $inputWrapperBoxShadowDisabled;
  }
}

// error
.glxy-form-field.glxy-form-field-invalid .input-wrapper:not(:focus-within) {
  // Show the error red outline when the input is not focused
  border-color: $error-border-color;
  box-shadow:
    0 0 0 1px $glxy-red-600,
    0 0 0 5px #{$glxy-red-600}11;
}

//
//
// Multi-control and general content wrapper

.glxy-form-field.show-input-decoration .multi-control-wrapper {
  background: $primary-background-color;
  border-radius: 8px;
  margin: $spacing-2 0;
  padding: 6px $spacing-3 $spacing-2;
}
.glxy-form-field .multi-control-wrapper:empty {
  display: none;
}

.glxy-form-field .general-content-wrapper {
  &:empty {
    display: none;
  }
}

//
//
// Color Input Wrapper

.glxy-form-field .color-input {
  background-color: $field-background-color;
  border: 1px solid $field-border-color;
  border-radius: $default-border-radius;
  float: left;
  margin-right: $spacing-2;
  box-shadow: $inputWrapperBoxShadow;

  &:empty {
    display: none;
  }
}

.glxy-form-field.glxy-form-field-disbled .color-input {
  background-color: $field-background-disabled-color;
  box-shadow: $inputWrapperBoxShadowDisabled;
}

//
//
// Help icon (in development)

.glxy-form-field .help-icon {
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
  color: $tertiary-font-color;
}
.glxy-form-field .inline-help-icon {
  font-size: 16px;
  line-height: 0;
  position: relative;
  top: 3px;
  color: $tertiary-font-color;
}

//
//
// Prefix and Suffix Tcons

.glxy-form-field .prefix-icon,
.glxy-form-field .suffix-icon {
  margin: auto 0;
  font-size: 18px;
  color: $icon-color;
  user-select: none;
}
.glxy-form-field .prefix-icon {
  padding-left: 10px;
  margin-right: -5px;
  flex-shrink: 0;
}
.glxy-form-field .suffix-icon {
  padding-right: 10px;
  flex-shrink: 0;
}
.glxy-form-field.glxy-form-field-disbled .prefix-icon,
.glxy-form-field.glxy-form-field-disbled .suffix-icon {
  color: $tertiary-text-color;
  opacity: 0.8;
}

.glxy-form-field.size-large {
  .prefix-icon,
  .suffix-icon {
    margin: auto 0;
    font-size: 21px;
    user-select: none;
  }
  .prefix-icon {
    padding-left: 14px;
    margin-right: -5px;
  }
  .suffix-icon {
    padding-right: 14px;
  }
}

//
//
// Prefix and Suffix Text

.glxy-form-field .prefix-text,
.glxy-form-field .suffix-text {
  color: $secondary-font-color;
  padding: 0 $spacing-3;
  border: 1px none $border-color;
  background: $field-background-disabled-color;
  white-space: nowrap;
  user-select: none;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.glxy-form-field .prefix-text {
  border-right-style: solid;
}
.glxy-form-field .suffix-text {
  border-left-style: solid;
}
.glxy-form-field.glxy-form-field-disbled .prefix-text,
.glxy-form-field.glxy-form-field-disbled .suffix-text {
  color: $tertiary-text-color;
}

//
//
//

.glxy-form-field .glxy-extended {
  display: block;
  color: $secondary-font-color;
  font-weight: normal;
  padding-top: 2px;
}

.glxy-form-field.glxy-form-field-disbled .glxy-extended,
.glxy-form-field [disabled] .glxy-extended,
.glxy-form-field .mat-checkbox-disabled .glxy-extended,
.glxy-form-field .mat-radio-disabled .glxy-extended {
  // TODO: remove old ruleset after MDC migration
  opacity: 0.8;
}

//
//
// Material overrides

.glxy-form-field {
  mat-datepicker-toggle .mat-mdc-icon-button {
    width: 36px;
    height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  // for mat-icon-buttons in matPrefix/matSuffix positions
  .input-wrapper > .mat-mdc-icon-button {
    width: 40px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 0;
    font-size: inherit;

    mat-icon {
      font-size: 22px;
      width: 22px;
      height: 22px;
    }

    svg {
      width: 22px;
      height: 22px;
      margin-top: -3px;
    }
  }

  &.size-small .input-wrapper > .mat-mdc-icon-button {
    height: 30px;

    mat-icon {
      margin-top: -3px;
    }
  }

  &.size-large .input-wrapper > .mat-mdc-icon-button {
    height: 46px;
  }
}

.glxy-form-field .mat-mdc-input-element {
  font: inherit;
}

//
//
// utilities

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

//
//
// Layouts

.row.row-gutters .col > .glxy-form-field.bottom-spacing--default,
.row.row-gutters > .glxy-form-field.bottom-spacing--default {
  // adjust margin so the bottom spacing is still 24px when
  // directly in a glxy-layout element
  margin-bottom: $spacing-2; // 8px
}

.glxy-form-field.horizontal-layout {
  display: flex;
  align-items: center;
  width: 100%;

  .label-and-hint {
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: $spacing-3;
    margin-bottom: 0;
  }
  .label-and-hint label {
    margin-bottom: 0;
  }
  .label-and-hint label:not(.visually-hidden):has(.glxy-label) + .glxy-label-hint {
    margin-top: 2px;
    margin-bottom: 0;
  }
  .input-and-hints {
    flex: 3;
  }
}
