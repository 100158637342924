@use 'design-tokens' as *;

// Input Over-rides
.glxy-form-field div[contenteditable] {
  border: none;
  padding: 10px 12px;
  outline: none;
  min-height: 36px;
  font-size: 14px;
  width: 100%;
  background-color: unset;
  color: $primary-font-color;
  flex-grow: 3;
  flex-shrink: 3;
  appearance: none;

  @media (max-width: $mobile-breakpoint-max) {
    font-size: 16px;
    min-height: 40px;
  }
}

// Sizes
.glxy-form-field.size-small div[contenteditable] {
  min-height: 30px;
}

.glxy-form-field.size-large div[contenteditable] {
  min-height: 46px;
  font-size: 15px;
  padding-left: 14px;
  padding-right: 14px;
}
