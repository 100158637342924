@use 'design-tokens' as *;

// Input Over-rides
.glxy-form-field textarea,
.glxy-form-field textarea.mat-mdc-input-element {
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  background-color: unset;
  font-family: $default-font-family;
  color: $primary-font-color;
  flex-grow: 3;
  flex-shrink: 3;
  appearance: none;
  overflow: auto;

  // mat-input-element changes margins
  // we reset their changes here
  margin: 0;

  // cdkTextareaAutosize does not properly take padding into
  // account when autosizing, so we have to work around this
  // with top and bottom margin instead
  padding: 10px 0;
  padding-right: 12px;
  margin-left: 12px;

  // this fixes a bug where an unused scrollbar is shown
  box-sizing: content-box;

  &:not(.cdk-textarea-autosize):not([rows]) {
    // set a min height for the textarea if no height is set
    // via rows or cdkTextareaAutosize
    min-height: 52px;
  }

  @media (max-width: $mobile-breakpoint-max) {
    font-size: 16px;
    &:not(.cdk-textarea-autosize) {
      height: $spacing-5;
    }
  }
}

.glxy-form-field textarea::placeholder {
  color: $tertiary-font-color;
  opacity: 0.75;
}

.glxy-form-field textarea:focus::placeholder {
  opacity: 0.4;
}

// remove color behind autofilled inputs
.glxy-form-field textarea:-webkit-autofill {
  box-shadow: 0 0 0 50px $field-background-color inset !important;
}

// Sizes

.glxy-form-field.size-small textarea {
  &:not(.cdk-textarea-autosize) {
    height: 30px;
    padding: 6px 0;
  }
}
.glxy-form-field.size-large textarea {
  font-size: 15px;
  padding: 14px 2px;
  &:not(.cdk-textarea-autosize) {
    height: 46px;
  }
}
