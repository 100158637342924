@use '@angular/material' as mat;
@use 'design-tokens' as *;

.glxy-form-field {
  // we need to make the radiobutton smaller to fit in the form field
  // https://material.angular.io/guide/theming#customizing-density
  @include mat.radio-density(-2);
}

.glxy-form-field .mat-mdc-radio-group {
  display: block;

  .mat-mdc-radio-button {
    display: block;
    margin-left: -5px;
    padding-bottom: 8px;
    padding-top: 2px;
  }

  .mdc-form-field {
    display: flex;
    align-items: flex-start;
  }

  label {
    padding-top: 6px;
  }
}

// color the inner circle of the radio button when not disabled
.glxy-form-field .mdc-radio:not(mdc-radio--disabled) .mdc-radio__outer-circle {
  background-color: $field-background-color;
}
