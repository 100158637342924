@use 'design-tokens' as *;

.glxy-form-field .mat-mdc-slider {
  // Note: the calc is used to make the slider fit properly in the form field
  width: calc(100% - 16px);
  margin-top: -6px;
  margin-bottom: -8px;
  flex-grow: 3;
  flex-shrink: 3;
}
