@use 'design-tokens' as *;

.glxy-form-field .mat-mdc-chip-grid {
  padding: 2px 6px;
  width: 100%;

  .mdc-evolution-chip-set__chips {
    align-items: center;
    margin-left: -4px;
  }

  input.mat-mdc-chip-input {
    margin-left: 2px;
    height: auto;
    min-height: 32px;
  }

  .mat-mdc-chip {
    line-height: 14px;
    padding: 2px 10px;
    height: 22px;
    margin-left: 4px;

    .mdc-evolution-chip__action--primary {
      padding: 0;
    }

    .mat-mdc-chip-remove {
      margin-right: -6px;
      padding: 0;
      padding-left: 8px;
    }
  }
}
