@use 'design-tokens' as *;

.glxy-form-field input:not([type='checkbox'], [type='radio'], [type='color']) {
  border: none;
  padding: 0 12px;
  outline: none;
  height: 36px;
  font-size: 14px;
  font-family: $default-font-family;
  width: 100%;
  background-color: unset;
  color: $primary-font-color;
  flex-grow: 3;
  flex-shrink: 3;
  appearance: none;
  margin-top: 0;

  &:disabled {
    color: $tertiary-font-color;
  }

  @media (max-width: $mobile-breakpoint-max) {
    font-size: 16px;
    height: 40px;
  }
}

.glxy-form-field input::placeholder {
  color: $tertiary-font-color;
  opacity: 0.75;
}

.glxy-form-field input:focus::placeholder {
  opacity: 0.4;
}

// Input types
.glxy-form-field input[type='color'] {
  border: none;
  outline: none;
  height: 36px;
  font-size: 14px;
  background-color: unset;
  appearance: none;
  margin-top: 0;
  padding: 0;
  width: 48px;
  cursor: pointer;
  &::-webkit-color-swatch-wrapper {
    padding: 6px;
  }
  &::-webkit-color-swatch {
    border-radius: 2px;
    border: none;
  }
  &::-moz-color-swatch {
    border: 6px solid white;
  }
}
.glxy-form-field.glxy-form-field-disbled input[type='color'] {
  cursor: unset;
}

.glxy-form-field input[type='number'] {
  appearance: textfield;
}

// remove the extra  left space in safari
.glxy-form-field input[type='search'] {
  &::-webkit-search-decoration {
    display: none;
  }
}

.glxy-form-field input[type='datetime-local'],
.glxy-form-field input[type='date'],
.glxy-form-field input[type='time'],
.glxy-form-field input[type='week'],
.glxy-form-field input[type='month'] {
  // Override "monospace" default font with our default fonts
  font-family: $default-font-family;
  // Fix layout bug in Webkit where input is slightly lower
  position: relative;
}

// remove mat-input-element style overrides
.mat-mdc-input-element::-webkit-inner-spin-button,
.mat-mdc-input-element::-webkit-calendar-picker-indicator,
.mat-mdc-input-element::-webkit-clear-button {
  font-size: unset;
}

// remove mat-input-element style overrides
.mat-input-element::-webkit-inner-spin-button,
.mat-input-element::-webkit-calendar-picker-indicator,
.mat-input-element::-webkit-clear-button {
  // TODO: remove this ruleset after MDC migration
  font-size: unset;
}

// Sizes

.glxy-form-field.size-small input:not([type='checkbox']):not([type='radio']):not([type='color']) {
  height: 30px;
}

.glxy-form-field.size-large input:not([type='checkbox']):not([type='radio']):not([type='color']) {
  height: 46px;
  font-size: 15px;
  padding-left: 14px;
  padding-right: 14px;
}
