// font-size tokens (based on presets)
$font-preset-1-size: 32px;
$font-preset-2-size: 20px;
$font-preset-3-size: 16px;
$font-preset-4-size: 14px;
$font-preset-5-size: 12px;
$font-preset-6-size: 10px;

// font-families (from bootstrap and github)

$default-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$monospace-font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

// mixins for preset typography

@mixin text-preset-1 {
  font-size: $font-preset-1-size;
  font-weight: 400;
  line-height: 1.2;
}

@mixin text-preset-2 {
  font-size: $font-preset-2-size;
  font-weight: 500;
  line-height: 1.2;
}

@mixin text-preset-3 {
  font-size: $font-preset-3-size;
  font-weight: 400;
  line-height: 1.4;
}

@mixin text-preset-3--bold {
  @include text-preset-3;
  font-weight: 700;
}

@mixin text-preset-4 {
  font-size: $font-preset-4-size;
  font-weight: 400;
  line-height: 1.4;
}

@mixin text-preset-4--bold {
  @include text-preset-4;
  font-weight: 700;
}

@mixin text-preset-5 {
  font-size: $font-preset-5-size;
  font-weight: 400;
  line-height: 1.4;
}

@mixin text-preset-5--bold {
  @include text-preset-5;
  font-weight: 700;
}

@mixin text-preset-6 {
  font-size: $font-preset-6-size;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}
